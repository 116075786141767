<template>
  <div class="vlt-loading">
    <img
      v-if="!isEdge"
      src="@/assets/icons/loading.svg"
      class="loading-ico"
      alt="loader"
      :style="{width: size}"
    />
    <div v-else class="alternateLoader"></div>
  </div>
</template>

<script>
export default {
  name: "Loading",
  props: {
    size: {
      type: String,
      default: "24px",
    },
  },
  data() {
    return {
      isEdge: false,
    };
  },
  created() {
    // Internet Explorer 6-11
    var isIE = /*@cc_on!@*/ false || !!document.documentMode;

    // Edge 20+
    this.isEdge = !isIE && !!window.StyleMedia;
  },
};
</script>

<style lang="scss" scoped>
@import "./Loading.scss"
</style>
